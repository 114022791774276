<template>
  <div >
    <el-tree
      :props="props"
      :load="loadNode"
      lazy
      show-checkbox
      @check-change="handleCheckChange">
    </el-tree>
  </div>
</template>

<script>
import bus from "../../service/bus";
export default {
  name: "commonTags",
  data() {
    return {
      props: {
        label: 'name',
        children: 'zones'
      },
      count: 1
    };
  },
  
  created() {

  },
  methods: {
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    handleNodeClick(data) {
      console.log(data);
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: 'region1' }, { name: 'region2' }]);
      }
      if (node.level > 3) return resolve([]);

      var hasChild;
      if (node.data.name === 'region1') {
        hasChild = true;
      } else if (node.data.name === 'region2') {
        hasChild = false;
      } else {
        hasChild = Math.random() > 0.5;
      }

      setTimeout(() => {
        var data;
        if (hasChild) {
          data = [{
            name: 'zone' + this.count++
          }, {
            name: 'zone' + this.count++
          }];
        } else {
          data = [];
        }

        resolve(data);
      }, 500);
    }
  },
  // watch: {
  //   $route(newValue) {
  //     this.setTags(newValue);
  //   },
  // },
  // computed: {
  //   showTags() {
  //     return this.tagsList && this.tagsList.length > 0;
  //   },
  //   // 主题颜色
  //   subjectColor(){
  //     return this.$store.state.tagColor
  //   },
  // },
};
</script>

<style scoped>
.tags {
  padding-top: 10px;
  position: relative;
  height: 50px;
  overflow: hidden;
  z-index: 1;
  background: #fff;
  padding-right: 120px;
  box-shadow: 0px 2px 6px rgba(115, 127, 128, 0.12);
}

.tags ul {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.tags-li {
  float: left;
  margin: 5px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 26px;
  line-height: 28px;
  border: 1px solid #e9eaec;
  background: #fff;
  padding: 0 5px 0 12px;
  vertical-align: middle;
  color: #666;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  border-radius: 2px;
}

.tags-li .active .tags-li-icon {
  background-color: red;
}

.tags-li:not(.active):hover {
  background: #f8f8f8;
}

.tags-li.active {
  color: #fff;
}

.tags-li-title {
  float: left;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
}

.tags-li.active .tags-li-title {
  color: #fff;
}

.tags-close-box {
  position: absolute;
  right: 5px;
  top: 0;
  box-sizing: border-box;
  text-align: center;
  height: 30px;
  z-index: 10;
}

/* .tags-li.active {
  border: 1px solid #6190e8;
  background-color: #6190e8;
} */
</style>
